<template>
  <div class="header-basket">
    <b-button id="HeaderBasketButton" class="counter-wrapper" variant="outline-control" size="sm" v-bind="$attrs" v-on="$listeners">
      <b-icon icon="cart"/>
      <counter :count="itemCount"/>
    </b-button>

    <!-- container="HeaderCartButton" -->
    <b-popover
      v-if="basketLastAction.type"
      target="HeaderBasketButton"
      container="HeaderBasketButton"
      placement="bottomleft"
      triggers="manual"
      :show="popover.show"
    >
      <i18n tag="p" :path="`${tPath}.popover.actiontype.${basketLastAction.type.toLowerCase()}`">
        <template v-for="(prop, pKey) in popoverActiontypeProperties" v-slot:[pKey]><strong :key="pKey">{{ prop }}</strong></template>
      </i18n>
      <b-button :href="$attrs.href" variant="primary" size="sm" block>{{ $t(`${tPath}.popover.basketbutton`) }}</b-button>
    </b-popover>
  </div>
</template>

<script>
import { COMPONENT_HEADERBASKET_POPOVER_HIDE_TIMEOUT } from '@/constants'

import { flatten } from '@/assets/js/helper/object'

import Counter from '@/components/Counter'

export default {
  name: 'HeaderBasket',
  inheritAttrs: false,
  components: {
    Counter
  },
  data () {
    return {
      popover: {
        show: false,
        timeout: null
      }
    }
  },
  computed: {
    itemCount () {
      return this.$store.getters['shoppingcart/getItemCount']
    },
    basketLastAction () {
      return this.$store.getters['shoppingcart/getLastAction']
    },
    popoverActiontypeProperties () {
      return flatten(this.basketLastAction)
    }
  },
  methods: {
    showPopover () {
      if (this.basketLastAction.type) {
        if (this.popover.timeout) clearTimeout(this.popover.timeout)

        this.popover.show = true
        this.popover.timeout = setTimeout(() => {
          this.popover.show = false
        }, COMPONENT_HEADERBASKET_POPOVER_HIDE_TIMEOUT)
      } else {
        if (this.popover.timeout) clearTimeout(this.popover.timeout)
        this.popover.show = false
      }
    }
  },
  created () {
    this.$store.dispatch('shoppingcart/getMinimal')
  },
  watch: {
    basketLastAction () {
      this.showPopover()
    }
  }
}
</script>

<style lang="scss">
$headerbasket-popover-min-width: 250px !default;
$headerbasket-popover-margin-x: calc((#{$btn-border-width} + #{$btn-padding-x-sm} + 0.625em) - (#{$popover-arrow-width * 0.5})) !default;

.header-basket {
  #HeaderBasketButton {
    &:not(:hover):not(:active) {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .popover {
    min-width: $headerbasket-popover-min-width;

    .arrow {
      // icon font-size = 125%, defined by bootstrap-vue
      // 1.25em / 2 = 0.625em
      margin-left: $headerbasket-popover-margin-x;
      margin-right: $headerbasket-popover-margin-x;
    }
  }
}
</style>
